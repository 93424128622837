import React from 'react';
import PropTypes from 'prop-types';
import { accordionContextType, accordionItemContextType } from './proptypes';

class AccordionItem extends React.Component {
  static propTypes = {
    eventKey: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static contextTypes = {
    accordion: accordionContextType.isRequired,
  };

  static childContextTypes = {
    accordionItem: accordionItemContextType.isRequired,
  };

  getChildContext() {
    const { eventKey } = this.props;
    const { id: parentId, onToggle, activeKey, getId } = this.context.accordion;
    const ids = {
      titleId: getId(eventKey, 'title'),
      bodyId: getId(eventKey, 'body'),
    };
    return {
      accordionItem: { parentId, activeKey, eventKey, ...ids, onToggle },
    };
  }

  render() {
    const { children, className } = this.props;
    const panelClasses = `panel ${className || 'panel-default'}`;
    return <div className={panelClasses}>{children}</div>;
  }
}

export default AccordionItem;
