import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Open = ({ question, name, error, metadata, editable, onActionClick, ...props }) => {
  const classes = classNames('form-group', { 'has-error': error });
  return (
    <div className={classes}>
      <label htmlFor={name}>{question.question_text}</label>
      {editable && (
        <React.Fragment>
          <button
            className="btn btn-xs btn-danger pull-right icon-space-l"
            type="button"
            onClick={() => onActionClick(question.id, 'Delete')}
          >
            <i className="fa fa-fw fa-trash" />
          </button>
          <button
            className="btn btn-xs btn-warning pull-right"
            type="button"
            onClick={() => onActionClick(question.id, 'Edit')}
          >
            <i className="fa fa-fw fa-pencil" />
          </button>
        </React.Fragment>
      )}
      <input
        {...props}
        type={metadata.input_type}
        className="form-control"
        name={name}
        placeholder={metadata.placeholder}
        onChange={props.onChange}
      />
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

Open.defaultProps = {
  metadata: {
    input_type: 'text',
    placeholder: '',
  },
  editable: false,
};

Open.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
  }).isRequired,
  metadata: PropTypes.shape({
    input_type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default Open;
