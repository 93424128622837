import React from 'react';
import PropTypes from 'prop-types';
import { questionPropTypes } from 'helpers/proptypes';
import QuestionType from './QuestionType';

const Question = ({
  question,
  index,
  name,
  error,
  selectedValue,
  editable,
  onActionClick,
  ...rest
}) => (
  <table className="table question-edit-table">
    <tbody>
      <tr>
        <td className="question">
          <p className="bold">Q{question.order_id || question.number}</p>
        </td>
        <td>
          <QuestionType
            {...rest}
            question={question}
            name={name}
            error={error}
            selectedValue={selectedValue}
            editable={editable}
            onActionClick={onActionClick}
          />
        </td>
      </tr>
    </tbody>
  </table>
);

Question.propTypes = {
  question: questionPropTypes.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  addRule: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func,
};

export default Question;
