import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import classNames from 'classnames';

const Aux = (props) => props.children;

const ChoiceMatrix = ({
  choiceMatrix,
  question,
  name,
  error,
  selectedValue,
  editable,
  onActionClick,
  ...props
}) => {
  const formClassNames = classNames('form-group', { 'has-error': error });
  return (
    <div className={formClassNames}>
      <label htmlFor={`choice-${question.id}`}>{question.question_text}</label>
      {editable && (
        <React.Fragment>
          <button
            className="btn btn-xs btn-danger pull-right icon-space-l"
            type="button"
            onClick={() => onActionClick(question.id, 'Delete')}
          >
            <i className="fa fa-fw fa-trash" />
          </button>
          <button
            className="btn btn-xs btn-warning pull-right"
            type="button"
            onClick={() => onActionClick(question.id, 'Edit')}
          >
            <i className="fa fa-fw fa-pencil" />
          </button>
        </React.Fragment>
      )}
      <table className="table table-condensed choice-matrix">
        <thead>
          <tr>
            <th className="choice-matrix-question" />
            {choiceMatrix &&
              choiceMatrix.choices &&
              choiceMatrix.choices.map((choice, idx) => (
                <th key={idx} className="center">
                  {choice.text}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {choiceMatrix &&
            choiceMatrix.labels &&
            choiceMatrix.labels.map((label, idx) => (
              <tr key={idx}>
                <td className="choice-matrix-question">
                  <div htmlFor={`${name}-${idx}`}>{label}</div>
                </td>
                <RadioGroup
                  {...props}
                  name={`${name}-${idx}`}
                  selectedValue={selectedValue[idx] || ''}
                  onChange={(value) =>
                    props.onChange({ target: { value, name: `${name}-${idx}` } })
                  }
                  Component={Aux}
                >
                  {choiceMatrix &&
                    choiceMatrix.choices &&
                    choiceMatrix.choices.map((choice, idx) => (
                      <td key={idx} className="center">
                        <div className="radio">
                          <label>
                            <Radio value={choice.value} />
                          </label>
                        </div>
                      </td>
                    ))}
                </RadioGroup>
              </tr>
            ))}
        </tbody>
      </table>
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

ChoiceMatrix.defaultProps = {
  selectedValue: [],
  editable: false,
};

ChoiceMatrix.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  choiceMatrix: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string.isRequired),
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
  selectedValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default ChoiceMatrix;
