import React from 'react';
import AccordionMatrix from './AccordionMatrix';
import ChoiceMatrix from './ChoiceMatrix';
import Media from 'react-media';

const MediaMatrix = (props) => (
  <Media query="(max-width: 599px)">
    {(matches) => (matches ? <AccordionMatrix {...props} /> : <ChoiceMatrix {...props} />)}
  </Media>
);

export default MediaMatrix;
