import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import Media from 'react-media';

const ChoiceMultipleFreeform = ({
  error,
  choices,
  inline = false,
  name,
  question,
  selectedValue,
  editable,
  onActionClick,
  ...props
}) => {
  const classes = classNames('form-group', { 'has-error': error });
  let inlineClass = inline ? 'checkbox-inline' : '';
  const freeformName = `freeform-${name}`;
  return (
    <Media query="(max-width: 599px)">
      {(matches) => {
        if (matches) {
          inlineClass = '';
        }
        return (
          <div className={classes}>
            <label htmlFor={name} className="label-control">
              {question.question_text}
            </label>
            {editable && (
              <React.Fragment>
                <button
                  className="btn btn-xs btn-danger pull-right icon-space-l"
                  type="button"
                  onClick={() => onActionClick(question.id, 'Delete')}
                >
                  <i className="fa fa-fw fa-trash" />
                </button>
                <button
                  className="btn btn-xs btn-warning pull-right"
                  type="button"
                  onClick={() => onActionClick(question.id, 'Edit')}
                >
                  <i className="fa fa-fw fa-pencil" />
                </button>
              </React.Fragment>
            )}
            <CheckboxGroup
              {...props}
              name={name}
              onChange={(value) => props.onChange({ target: { name, value } })}
              value={selectedValue}
              checkboxDepth={3}
            >
              {choices &&
                choices.map((choice) => {
                  return (
                    <div className="checkbox" key={choice.value}>
                      <label className={inlineClass}>
                        <Checkbox value={choice.value} />
                        {choice.text}
                      </label>
                    </div>
                  );
                })}
            </CheckboxGroup>
            <label htmlFor={freeformName} className="icon-space-r">
              Other
            </label>
            <input
              type="text"
              name={freeformName}
              placeholder=""
              onChange={(e) =>
                props.onChange({
                  target: { value: e.target.value, name: e.target.name },
                  freeform: true,
                })
              }
            />
            {error ? <span className="help-block">{error}</span> : null}
          </div>
        );
      }}
    </Media>
  );
};

ChoiceMultipleFreeform.defaultProps = {
  selectedValue: [],
  editable: false,
};

ChoiceMultipleFreeform.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  inline: PropTypes.bool,
  choices: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
      .isRequired
  ),
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default ChoiceMultipleFreeform;
