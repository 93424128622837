import React from 'react';
import PropTypes from 'prop-types';
import { accordionItemContextType } from './proptypes';

class AccordionItemTitle extends React.Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  };

  static contextTypes = {
    accordionItem: accordionItemContextType.isRequired,
  };

  render() {
    const { eventKey, activeKey, onToggle, titleId, bodyId, parentId } = this.context.accordionItem;
    const expanded = eventKey === activeKey;
    return (
      <div className="panel-heading" role="tab" id={titleId}>
        <h4 className="panel-title">
          <a
            role="button"
            data-toggle="collapse"
            data-parent={parentId}
            href={`#${bodyId}`}
            aria-expanded={expanded.toString()}
            aria-controls={bodyId}
            onClick={(e) => onToggle(eventKey, expanded, e)}
          >
            {this.props.title}
          </a>
        </h4>
      </div>
    );
  }
}

export default AccordionItemTitle;
