import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import classNames from 'classnames';
import Media from 'react-media';

const Choice = ({
  inline = false,
  question,
  error,
  name,
  selectedValue,
  editable,
  onActionClick,
  ...props
}) => {
  let radioClassNames = inline ? 'radio-inline' : 'radio';
  const formClassNames = classNames('form-group', { 'has-error': error });
  return (
    <Media query="(max-width: 599px)">
      {(matches) => {
        if (matches) {
          radioClassNames = 'radio';
        }
        return (
          <div className={formClassNames}>
            <label htmlFor={name}>{question.question_text}</label>
            {editable && (
              <React.Fragment>
                <button
                  className="btn btn-xs btn-danger pull-right icon-space-l"
                  type="button"
                  onClick={() => onActionClick(question.id, 'Delete')}
                >
                  <i className="fa fa-fw fa-trash" />
                </button>
                <button
                  className="btn btn-xs btn-warning pull-right"
                  type="button"
                  onClick={() => onActionClick(question.id, 'Edit')}
                >
                  <i className="fa fa-fw fa-pencil" />
                </button>
              </React.Fragment>
            )}
            <RadioGroup
              selectedValue={selectedValue}
              name={name}
              {...props}
              onChange={(value) => {
                props.onChange({ target: { name, value } });
              }}
            >
              {props.choices &&
                props.choices.map((choice) => (
                  <div className={radioClassNames} key={choice.value}>
                    <label className={radioClassNames}>
                      <Radio value={choice.value} />
                      {choice.text}
                    </label>
                  </div>
                ))}
            </RadioGroup>
            {error ? <span className="help-block">{error}</span> : null}
          </div>
        );
      }}
    </Media>
  );
};

Choice.defaultProps = {
  selectedValue: '',
  editable: false,
};

Choice.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
    question_type: PropTypes.string.isRequired,
  }).isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default Choice;
