import PropTypes from 'prop-types';

export const accordionContextType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  activeKey: PropTypes.string,
  getId: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
});

export const accordionItemContextType = PropTypes.shape({
  parentId: PropTypes.string.isRequired,
  activeKey: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  bodyId: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
});
