import React from 'react';
import PropTypes from 'prop-types';
import { accordionContextType } from './proptypes';

class Accordion extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onSelect: PropTypes.func,
    activeKey: PropTypes.string,
    generateChildId: PropTypes.func,
  };

  static childContextTypes = {
    accordion: accordionContextType.isRequired,
  };

  static defaultProps = {
    onSelect: () => {},
  };

  state = {
    activeKey: this.props.activeKey,
  };

  defaultGenerateChildId = (eventKey, type) => `${this.props.id}-${type}-${eventKey}`;

  getChildContext() {
    const { activeKey } = this.state;
    const { id, generateChildId } = this.props;
    const getId = generateChildId || this.defaultGenerateChildId;
    return {
      accordion: { id, activeKey, getId, onToggle: this.handleToggle },
    };
  }

  handleToggle = (key, expanded, e) => {
    if (expanded) {
      this.setState({ activeKey: '' });
    } else {
      this.setState({ activeKey: key });
    }
    this.props.onSelect(key, expanded, e);
  };

  render() {
    const { id, children } = this.props;
    return (
      <div className="panel-group" id={id} role="tablist" aria-multiselectable="true">
        {children}
      </div>
    );
  }
}

export default Accordion;
