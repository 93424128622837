import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import classNames from 'classnames';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'components/shared/accordion';

const Aux = (props) => props.children;

const AccordionMatrix = ({
  choiceMatrix,
  question,
  name,
  error,
  selectedValue,
  editable,
  onActionClick,
  ...props
}) => {
  const formClassNames = classNames('form-group', { 'has-error': error });
  return (
    <div className={formClassNames}>
      <label htmlFor={`choice-${question.id}`}>{question.question_text}</label>
      {editable && (
        <React.Fragment>
          <button
            className="btn btn-xs btn-danger pull-right icon-space-l"
            type="button"
            onClick={() => onActionClick(question.id, 'Delete')}
          >
            <i className="fa fa-fw fa-trash" />
          </button>
          <button
            className="btn btn-xs btn-warning pull-right"
            type="button"
            onClick={() => onActionClick(question.id, 'Edit')}
          >
            <i className="fa fa-fw fa-pencil" />
          </button>
        </React.Fragment>
      )}
      <Accordion id={name}>
        {choiceMatrix &&
          choiceMatrix.labels &&
          choiceMatrix.labels.map((label, idx) => (
            <AccordionItem eventKey={`${name}-${idx}`} key={idx}>
              <AccordionItemTitle title={label} />
              <AccordionItemBody>
                <RadioGroup
                  {...props}
                  name={`${name}-${idx}`}
                  selectedValue={selectedValue[idx] || ''}
                  onChange={(value) =>
                    props.onChange({ target: { value, name: `${name}-${idx}` } })
                  }
                  Component={Aux}
                >
                  {choiceMatrix.choices &&
                    choiceMatrix.choices.map((choice, idx) => (
                      <div className="radio" key={choice.value}>
                        <label>
                          <Radio value={choice.value} />
                          {choice.text}
                        </label>
                      </div>
                    ))}
                </RadioGroup>
              </AccordionItemBody>
            </AccordionItem>
          ))}
      </Accordion>
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

AccordionMatrix.defaultProps = {
  selectedValue: [],
  editable: false,
};

AccordionMatrix.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  choiceMatrix: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string.isRequired),
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
  selectedValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default AccordionMatrix;
