import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BaseForm } from 'react-validify';
import { questionBlockPropTypes } from 'helpers/proptypes';
import { Loading } from 'components';
import Question from './Question';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

export default class QuestionBlock extends React.Component {
  static propTypes = {
    block: questionBlockPropTypes.isRequired,
    onClick: PropTypes.func,
    generateId: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    onQuestionActionClick: PropTypes.func,
    onBlockActionClick: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => null,
    editable: false,
  };

  state = {
    rules: {},
    fields: {},
  };

  handleAnswerChange = ({ target, freeform }) => {
    this.setState((prevState, _) => ({
      fields: { ...prevState.fields, ...{ [target.name]: target.value } },
    }));
  };

  addRule = (question) => {
    const id = this.props.generateId(question);
    if (question.metadata && question.metadata.choiceMatrix) {
      const matrixRules = question.metadata.choiceMatrix.labels.reduce((accum, _, idx) => {
        const matrixId = `${id}-${idx}`;
        return { ...accum, ...{ [matrixId]: 'required' } };
      }, {});
      const matrixFields = Object.keys(matrixRules).reduce((accum, matrixRule, idx) => {
        if (!this.state.fields[matrixRule]) {
          const answer = get(
            question,
            ['answer', 'response', 'value', question.metadata.choiceMatrix.labels[idx]],
            ''
          );
          return { ...accum, ...{ [matrixRule]: answer } };
        }
        return accum;
      }, {});
      this.setState((prevState, _) => ({
        rules: { ...prevState.rules, ...matrixRules },
        fields: { ...prevState.fields, ...matrixFields },
      }));
    } else {
      if (!this.state.rules[id]) {
        this.setState((prevState, _) => ({
          rules: { ...prevState.rules, ...{ [id]: 'required' } },
        }));
      }
      if (!this.state.fields[id]) {
        const answer = (question.answer && question.answer.response.value) || '';
        this.setState((prevState, _) => ({ fields: { ...prevState.fields, ...{ [id]: answer } } }));
      }
    }
    return id;
  };

  componentDidMount() {
    this.props.block.questions.forEach((question) => this.addRule(question));
  }

  componentDidUpdate(prevProps) {
    const prevIds = new Set(prevProps.block.questions.map((q) => q.number));
    const currIds = new Set(this.props.block.questions.map((q) => q.number));
    if (!isEqual(prevIds, currIds)) {
      this.props.block.questions.forEach((question) => this.addRule(question));
    }
  }

  render() {
    const errorMessages = { required: 'Please answer this question.' };
    const { block, onClick, editable, onBlockActionClick, onQuestionActionClick } = this.props;

    const saveBtnIconClasses = classNames('fa icon-space-r', {
      'fa-check': !block.isSaving,
      'fa-circle-o-notch fa-spin': block.isSaving,
    });

    const saveBtnClasses = classNames('btn btn-outline-info', {
      muted: block.isSaving,
    });

    const fieldsKey = Object.keys(this.state.fields).join(',');

    return (
      <div className="survey question-block">
        {editable && (
          <div className="col-xs-12">
            <button
              type="button"
              className="btn btn-xs btn-default pull-right icon-space-l"
              onClick={() => onBlockActionClick(block.id, 'Delete')}
            >
              <i className="fa fa-fw fa-trash" />
            </button>
            <button
              type="button"
              className="btn btn-xs btn-default pull-right icon-space-l"
              onClick={() => onBlockActionClick(block.id, 'Edit')}
            >
              <i className="fa fa-fw fa-pencil" />
            </button>
            <button
              type="button"
              className="btn btn-xs btn-outline-info pull-right"
              onClick={() => onQuestionActionClick(null, 'Add')}
            >
              <i className="fa fa-plus icon-space-r" />
              New Question
            </button>
          </div>
        )}
        <div className="question-block-header">{block.title}</div>
        <p className="bold text-size mt1 mb2">{block.intro}</p>
        <BaseForm
          key={fieldsKey}
          values={this.state.fields}
          errors={this.state.errors}
          rules={this.state.rules}
          onErrors={(errors) => {
            let matrixErrors = {};
            if (errors) {
              matrixErrors = Object.keys(errors).filter((key) => key.startsWith('choice-matrix'));
              matrixErrors = new Set(
                matrixErrors.map((key) =>
                  key
                    .split('-')
                    .slice(0, 3)
                    .join('-')
                )
              );
              matrixErrors = [...matrixErrors].reduce(
                (acc, error) => ({ ...acc, ...{ [error]: errorMessages['required'] } }),
                {}
              );
            }
            this.setState({ errors: { ...errors, ...matrixErrors } });
          }}
          onValues={(values) => this.setState({ fields: values })}
          errorMessages={errorMessages}
        >
          {block.questions.map((question, idx) => {
            if (question.isLoading) {
              return <Loading key={idx} />;
            }
            const name = this.props.generateId(question);
            let selectedValue = this.state.fields[name];
            if (name.startsWith('choice-matrix')) {
              selectedValue = Object.keys(this.state.fields)
                .filter((key) => key.startsWith('choice-matrix'))
                .map((key) => this.state.fields[key]);
            }
            return (
              <Question
                selectedValue={selectedValue}
                name={name}
                key={`Q${question.id}`}
                question={question}
                index={idx + 1}
                onChange={this.handleAnswerChange}
                editable={editable}
                onActionClick={onQuestionActionClick}
              />
            );
          })}
          {!editable && (
            <button
              submit
              type="button"
              onClick={() => onClick(block.id, this.state.fields)}
              className={saveBtnClasses}
              disabled={block.isSaving}
            >
              <i className={saveBtnIconClasses} />
              Save
            </button>
          )}
        </BaseForm>
      </div>
    );
  }
}
