import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { accordionItemContextType } from './proptypes';

class AccordionItemBody extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static contextTypes = {
    accordionItem: accordionItemContextType.isRequired,
  };

  render() {
    const { eventKey, activeKey, titleId, bodyId } = this.context.accordionItem;
    const expanded = eventKey === activeKey;
    const classes = classNames('panel-collapse collapse', { in: expanded });
    return (
      <div id={bodyId} className={classes} role="tabpanel" aria-labelledby={titleId}>
        <div className="panel-body">{this.props.children}</div>
      </div>
    );
  }
}

export default AccordionItemBody;
