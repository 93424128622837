import React from 'react';
import PropTypes from 'prop-types';

import { questionPropTypes } from 'helpers/proptypes';
import { QuestionTypeDict } from 'helpers/dictionaries';

import YesNo from '../shared/YesNo';
import YesNoComment from '../shared/YesNoComment';
import YesNoIdk from '../shared/YesNoIdk';
import Open from '../shared/Open';
import OpenText from '../shared/OpenText';
import Choice from '../shared/Choice';
import MediaMatrix from '../shared/MediaMatrix';
import ChoiceMultiple from '../shared/ChoiceMultiple';
import ChoiceMultipleFreeform from '../shared/ChoiceMultipleFreeform';

const QuestionType = ({ question, onChange, name, error, selectedValue, ...props }) => {
  switch (question.question_type) {
    case QuestionTypeDict.YES_NO:
      return (
        <YesNo
          {...props}
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    case QuestionTypeDict.YES_NO_COMMENT:
      return (
        <YesNoComment
          {...props}
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    case QuestionTypeDict.YES_NO_IDK:
      return (
        <YesNoIdk
          {...props}
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    case QuestionTypeDict.OPEN:
      return (
        <Open
          {...props}
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    case QuestionTypeDict.OPEN_TEXT:
      let { metadata, ...rest } = question;
      return (
        <OpenText
          {...props}
          question={rest}
          onChange={onChange}
          metadata={metadata}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    case QuestionTypeDict.CHOICE: {
      let { metadata, ...rest } = question;
      return (
        <Choice
          {...props}
          question={rest}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
          choices={metadata.choices}
          inline={metadata.inline}
        />
      );
    }
    // case QuestionTypeDict.CHOICE_FREEFORM
    //   return <ChoiceFreeform question={question} />;
    case QuestionTypeDict.CHOICE_MULTIPLE: {
      let { metadata, ...rest } = question;
      return (
        <ChoiceMultiple
          {...props}
          question={rest}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
          choices={metadata.choices}
          inline={metadata.inline}
        />
      );
    }
    case QuestionTypeDict.CHOICE_MULTIPLE_FREEFORM: {
      let { metadata, ...rest } = question;
      return (
        <ChoiceMultipleFreeform
          {...props}
          question={rest}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
          choices={metadata.choices}
          inline={metadata.inline}
        />
      );
    }
    case QuestionTypeDict.CHOICE_MATRIX: {
      let { metadata, ...rest } = question;
      return (
        <MediaMatrix
          {...props}
          question={rest}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
          choiceMatrix={metadata.choiceMatrix}
        />
      );
    }
    // case QuestionTypeDict.RANGE:
    //   return <Range question={question} />;
    case QuestionTypeDict.NUMBER:
      return (
        <Open
          {...props}
          input_type="number"
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
    // case QuestionTypeDict.COMMENT:
    //   return <Comment question={question} />;
    default:
      return (
        <Open
          {...props}
          question={question}
          onChange={onChange}
          name={name}
          error={error}
          selectedValue={selectedValue}
        />
      );
  }
};

QuestionType.propTypes = {
  question: questionPropTypes.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func,
  selectedValue: PropTypes.string.isRequired,
};

export default QuestionType;
