import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import classNames from 'classnames';

const YesNoIdk = ({ question, error, name, selectedValue, editable, onActionClick, ...props }) => {
  const formClassNames = classNames('form-group', { 'has-error': error });
  return (
    <div className={formClassNames}>
      <label htmlFor={name}>{question.question_text}</label>
      {editable && (
        <React.Fragment>
          <button
            className="btn btn-xs btn-danger pull-right icon-space-l"
            type="button"
            onClick={() => onActionClick(question.id, 'Delete')}
          >
            <i className="fa fa-fw fa-trash" />
          </button>
          <button
            className="btn btn-xs btn-warning pull-right"
            type="button"
            onClick={() => onActionClick(question.id, 'Edit')}
          >
            <i className="fa fa-fw fa-pencil" />
          </button>
        </React.Fragment>
      )}
      <RadioGroup
        {...props}
        name={name}
        selectedValue={selectedValue}
        onChange={(value) => props.onChange({ target: { name, value } })}
      >
        <div className="radio">
          <label>
            <Radio value="true" />
            Yes
          </label>
        </div>
        <div className="radio">
          <label>
            <Radio value="false" />
            No
          </label>
        </div>
        <div className="radio">
          <label>
            <Radio value="null" />
            Not sure
          </label>
        </div>
      </RadioGroup>
      {error ? <span className="help-block">{error}</span> : null}
    </div>
  );
};

YesNoIdk.defaultProps = {
  selectedValue: '',
  editable: false,
};

YesNoIdk.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question_text: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onActionClick: PropTypes.func,
  editable: PropTypes.bool.isRequired,
};

export default YesNoIdk;
